import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import router from '@/router';

const environment = import.meta.env.MODE;
const dsn = import.meta.env.VITE_SENTRY_DSN;
const baseURL = import.meta.env.VITE_BASE_URL;
const isProduction = environment === 'prod';

const sentrySettings = {
  environment,
  dsn,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', baseURL, /^\//],
    }),
  ],
  debug: !isProduction,
  // tracesSampleRate: import.meta.env.MODE === 'prod' ? 0.2 : 1,
  tracingOptions: {
    trackComponents: true,
  },
  // Vue specific
  logErrors: !isProduction,
  attachProps: true,
  attachStacktrace: true,
};

export default sentrySettings;
