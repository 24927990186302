import { noop } from '@zoomcatalog/shared';
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';

import { useAuthStore } from '@/composable/store-utils/useAuthStore';
import api from '@/config/axios';
import { GET_COMPANY_SETTINGS_BY_KEY } from '@/config/endpoints';
import MainContainer from '@/layouts/MainContainer.vue';
import {
  isRestrictionsSetting,
  Restrictions,
} from '@/service/request-handlers/useCompanySettingSectionQuery';
import { IDesign } from '@/service/ts-definitions';
import { useSelector } from '@/store/rootStore';
import { storageInstances } from '@/utils/storageInstances';

type RouteWithMeta = RouteRecordRaw & {
  meta?: {
    requiresAuth?: boolean;
    checkForCompanyRestrictions?: boolean;
  };
};

let hasCheckedCompanyRestrictions = false;

const routes: Array<RouteWithMeta> = [
  {
    path: '/:projectId/:slug',
    component: MainContainer,
    meta: {
      requiresAuth: true,
      checkForCompanyRestrictions: true,
    },
    children: [
      {
        path: '/:projectId/:slug',
        redirect: (to) => ({
          name: 'Viewer',
          params: { ...to.params, pageNumber: 1 },
        }),
      },
      {
        name: 'Viewer',
        path: '/:projectId/:slug/:pageNumber',
        component: () =>
          import(/* webpackChunkName: "viewer" */ '@/views/Viewer.vue'),
      },
    ],
  },
  {
    name: 'SimplePage',
    path: '/:projectId/:slug/simple-page/:pageNumber',
    component: () =>
      import(/* webpackChunkName: "simple-page" */ '@/views/SimplePage.vue'),
  },
  {
    name: 'SimplePublishedPage',
    path: '/:projectId/:slug/simple-published-page/:pageNumber',
    component: () =>
      import(
        /* webpackChunkName: "simple-published-page" */ '@/views/SimplePublishedPage.vue'
      ),
  },
  {
    name: 'ThumbnailPage',
    path: '/:projectId/:slug/thumbnail-page/:pageNumber',
    component: () =>
      import(
        /* webpackChunkName: "thumbnail-page" */ '@/views/ThumbnailPage.vue'
      ),
  },
  {
    name: 'ThumbnailPublishedPage',
    path: '/:projectId/:slug/thumbnail-published-page/:pageNumber',
    component: () =>
      import(
        /* webpackChunkName: "thumbnail-published-page" */ '@/views/ThumbnailPublishedPage.vue'
      ),
  },
  {
    name: '404',
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const navigationGuards = {
  requiresAuth: async (to: RouteLocationNormalized) => {
    if (!to.matched.some((record) => record.meta.requiresAuth)) return;

    const { isAuthorized, tokenExchange } = useAuthStore();
    const sessionId = to.query.sessionId?.toString();
    if (sessionId) {
      await tokenExchange(sessionId);
    }
    const temporalToken = to.query.t?.toString();
    await isAuthorized(temporalToken);
    const newQuery = to.query;
    const hasAuthQueries =
      newQuery.t || newQuery.sessionId || newQuery.supplier_id;
    if (hasAuthQueries) {
      if (newQuery.supplier_id) {
        storageInstances.lastCompanyId.set(
          newQuery.distributor_id?.toString() ?? newQuery.supplier_id.toString()
        );
      }

      delete newQuery.t;
      delete newQuery.sessionId;
      delete newQuery.supplier_id;
      return { path: to.path, query: newQuery };
    }
  },
  checkForCompanyRestrictions: async (to: RouteLocationNormalized) => {
    if (!to.matched.some((record) => record.meta.checkForCompanyRestrictions))
      return;

    if (hasCheckedCompanyRestrictions) return;

    const publishedDesign = await api
      .get<IDesign | undefined>(`project/${to.params.projectId}/publish`)
      .then((data) => data.data)
      .catch(noop);
    const endpoints = useSelector((state) => state.sniffer.endpoints);
    if (!publishedDesign?.company_id) return;

    const restrictionsResponse = await api
      .get<Restrictions | undefined>(
        GET_COMPANY_SETTINGS_BY_KEY.endpoint('restrictions'),
        {
          headers: {
            'X-Zoom-Company': publishedDesign.company_id,
          },
          baseURL: endpoints.value.COMPANIES_API_ENDPOINT,
        }
      )
      .then((data) => data.data)
      .catch(noop);
    if (!isRestrictionsSetting(restrictionsResponse)) return;

    let restrictionKey: string | undefined;
    if (publishedDesign?.is_template) {
      restrictionKey = 'smart-layouts-templates.detail';
    } else {
      restrictionKey = 'smart-layouts.detail';
    }

    const hasRestriction = restrictionKey in restrictionsResponse.restrictions;
    hasCheckedCompanyRestrictions = true;

    if (hasRestriction) return '/404';
  },
};

router.beforeEach(async (to, from, next) => {
  document.title = import.meta.env.VITE_TITLE;
  const navigationGuardList = Object.values(navigationGuards);

  for (const navigationGuard of navigationGuardList) {
    const routeGuardResponse = await navigationGuard(to);
    if (routeGuardResponse) return next(routeGuardResponse);
  }

  next();
});

export default router;
