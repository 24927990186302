import { useLocalStorage } from '@zoomcatalog/composable';

const userToken = useLocalStorage('userToken');
const refreshToken = useLocalStorage('refreshToken');
const sessionId = useLocalStorage('sessionId');
const lastCompanyId = useLocalStorage('lastCompanyId');

export const storageInstances = {
  userToken,
  refreshToken,
  sessionId,
  lastCompanyId,
};
