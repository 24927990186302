import { Module } from 'vuex';
import { UserCompany } from '@/service/request-handlers/useUserCompanies';
import { UserDistributorCompany } from '@/service/request-handlers/useUserDistributorCompanies';
import { Company, EventPayload } from '@/service/ts-definitions';
import { RootStore } from '../rootStore';

export interface CompanyStore {
  company: Company | null;
  selectedCompanyId?: string | null;
  userCompanies?: Array<UserCompany>;
  distributorCompanies?: Array<UserDistributorCompany>;
}

type CompanyNamespace = 'company';

type SetCompanyMutation = EventPayload<
  `${CompanyNamespace}/setCompany`,
  Company
>;
type SetSelectedCompanyIdMutation = EventPayload<
  `${CompanyNamespace}/setSelectedCompanyId`,
  string
>;
type SetUserCompaniesMutation = EventPayload<
  `${CompanyNamespace}/setUserCompanies`,
  Array<UserCompany>
>;
type SetDistributorCompaniesMutation = EventPayload<
  `${CompanyNamespace}/setDistributorCompanies`,
  Array<UserDistributorCompany>
>;

export type CompanyMutations =
  | SetCompanyMutation
  | SetSelectedCompanyIdMutation
  | SetUserCompaniesMutation
  | SetDistributorCompaniesMutation;

export const companyStore: Module<CompanyStore, RootStore> = {
  namespaced: true,
  state: {
    company: null,
    selectedCompanyId: null,
    userCompanies: [],
    distributorCompanies: [],
  },
  mutations: {
    setCompany(state, { payload }: SetCompanyMutation) {
      state.company = payload;
    },
    setSelectedCompanyId(state, { payload }: SetSelectedCompanyIdMutation) {
      state.selectedCompanyId = payload;
    },
    setUserCompanies(state, { payload }: SetUserCompaniesMutation) {
      state.userCompanies = payload;
    },
    setDistributorCompanies(
      state,
      { payload }: SetDistributorCompaniesMutation
    ) {
      state.distributorCompanies = payload;
    },
  },
};
