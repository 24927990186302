interface Settings {
  SEND_TRACKING_EVENTS: boolean;
  ENABLE_MSW: boolean;
  QC_SIMPLE_PAGE_URL: (projectId: string) => string;
  QC_UI_URL: (slug: string) => string;
  TRACKING_URL: string;
  GEOLOCATION_URL: string;
  SLUGS: {
    SHOWDOWNDISPLAYS: string;
  };
}

type ENV = 'development' | 'alpha' | 'beta' | 'prod';

const AMROD_QC_VIEWER_URL = `https://studio.promoafrica.com`;
const AMROD_QC_UI_URL = `https://create.amrodstudio.co.za`;

const sharedKeys = {
  SEND_TRACKING_EVENTS: true,
  ENABLE_MSW: false,
  QC_SIMPLE_PAGE_URL: (projectId: string) =>
    `${
      import.meta.env.VITE_BASE_URL
    }/${projectId}/download/simple-published-page`,
  SLUGS: {
    SHOWDOWNDISPLAYS: 'showdowndisplays',
  },
};

const VUE_APP: Record<ENV, Settings> = {
  development: {
    ...sharedKeys,
    ENABLE_MSW: true,
    QC_UI_URL: (slug: string) =>
      `https://alpha-${slug}-quickcanvas.zoomcatalog.com`,
    TRACKING_URL:
      'https://alpha-tracker.zoomcatalog.com/stats/zoomcatalog/data',
    GEOLOCATION_URL: 'https://alpha-analytics-geolocalization.zoomcatalog.com/',
  },

  alpha: {
    ...sharedKeys,
    QC_UI_URL: (slug: string) =>
      `https://alpha-${slug}-quickcanvas.zoomcatalog.com`,
    TRACKING_URL:
      'https://alpha-tracker.zoomcatalog.com/stats/zoomcatalog/data',
    GEOLOCATION_URL: 'https://alpha-analytics-geolocalization.zoomcatalog.com/',
  },

  beta: {
    ...sharedKeys,
    QC_UI_URL: (slug: string) =>
      `https://beta-${slug}-quickcanvas.zoomcatalog.com`,
    TRACKING_URL: 'https://beta-tracker.zoomcatalog.com/stats/zoomcatalog/data',
    GEOLOCATION_URL: 'https://beta-analytics-geolocalization.zoomcatalog.com/',
  },

  prod: {
    ...sharedKeys,
    QC_UI_URL: (slug: string) => {
      if (document.location.origin === AMROD_QC_VIEWER_URL)
        return AMROD_QC_UI_URL;

      return `https://${slug}-quickcanvas.zoomcatalog.com`;
    },
    TRACKING_URL: 'https://prod-tracker.zoomcatalog.com/stats/zoomcatalog/data',
    GEOLOCATION_URL: 'https://prod-analytics-geolocalization.zoomcatalog.com/',
  },
};

export const settings: Settings = VUE_APP[import.meta.env.MODE as ENV];
