<template>
  <ZNotification v-bind="notificationProps" @close="onClose">
    {{ notificationProps.message }}
  </ZNotification>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZNotification } from '@zoomcatalog/design-system';
import { useCommit, useSelector } from '@/store/rootStore';

export default defineComponent({
  name: 'GlobalNotification',
  components: {
    ZNotification,
  },
  setup() {
    const notificationProps = useSelector((state) => state.notification);
    const commit = useCommit();

    return {
      notificationProps,
      onClose: () => commit({ type: 'notification/closeNotification' }),
    };
  },
});
</script>
