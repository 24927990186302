import { computed, ComputedRef, InjectionKey } from 'vue';
import {
  createLogger,
  createStore,
  Plugin,
  Store,
  useStore as baseUseStore,
} from 'vuex';
import { AuthMutations, authStore, AuthStore } from './modules/auth';
import {
  CompanyMutations,
  companyStore,
  CompanyStore,
} from './modules/company';
import {
  NotificationMutations,
  notificationStore,
  NotificationStore,
} from './modules/notification';
import {
  ProjectMutations,
  projectStore,
  ProjectStore,
} from './modules/project';
import {
  SnifferMutations,
  snifferStore,
  SnifferStore,
} from './modules/sniffer';
export interface RootStore {
  auth: AuthStore;
  company: CompanyStore;
  project: ProjectStore;
  notification: NotificationStore;
  sniffer: SnifferStore;
}
export const rootStore = createStore<RootStore>({
  state: undefined,
  mutations: {},
  actions: {},
  modules: {
    auth: authStore,
    company: companyStore,
    project: projectStore,
    notification: notificationStore,
    sniffer: snifferStore,
  },
  plugins: [
    import.meta.env.MODE === 'development' &&
      createLogger<RootStore>({
        mutationTransformer(mutation) {
          return {
            type: mutation.type,
            payload: (mutation as any)?.payload?.payload,
          };
        },
      }),
  ].filter(Boolean) as Plugin<RootStore>[],
});
export const key: InjectionKey<Store<RootStore>> = Symbol();
export function useStore() {
  return baseUseStore(key);
}
export type StoreMutations =
  | AuthMutations
  | CompanyMutations
  | ProjectMutations
  | NotificationMutations
  | SnifferMutations;

export function useCommit() {
  return (action: StoreMutations) => rootStore.commit(action);
}
export type StoreActions = any;
export function useDispatch() {
  return async (action: StoreActions) => rootStore.dispatch(action);
}
function useBaseSelector<TState = Record<string, unknown>, TSelected = unknown>(
  selector: (state: TState) => TSelected
): ComputedRef<TSelected>;
function useBaseSelector(selector: (state: any) => any): any {
  return computed(() => selector(rootStore.state));
}
export type TypedUseSelector<TState> = <TSelected>(
  selector: (state: TState) => TSelected
) => ComputedRef<TSelected>;

export const useSelector: TypedUseSelector<RootStore> = useBaseSelector;
