import { useNotification } from '@zoomcatalog/design-system';
import { isAxiosError } from 'axios';
import { isEnqueueNotification } from '@/components/feedback';
import { useCommit } from '@/store/rootStore';

export function errorHandler(err: unknown) {
  const commit = useCommit();
  const notification = useNotification({
    onNotificationEvent(event) {
      if (isEnqueueNotification(event)) {
        commit({ type: event.type, payload: event.payload });
      }
    },
  });

  if (isAxiosError(err)) {
    const errorData = err.response?.data as { message: string };
    notification.enqueueNotification({
      message: errorData.message ?? err.message,
      color: 'danger',
    });
  } else if (err instanceof Error) {
    notification.enqueueNotification({
      message: err.message,
      color: 'danger',
    });
  } else {
    notification.enqueueNotification({
      message: 'Something went wrong',
      color: 'danger',
    });
  }
}
