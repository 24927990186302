import {
  removeAuthHeader,
  removeRefreshTokenInterceptor,
  setAuthHeader,
  setRefreshTokenInterceptor,
} from '@/config/axios';
import { useAuthService } from '@/service/request-handlers/useAuthService';
import { useCommit, useSelector } from '@/store/rootStore';
import { errorHandler } from '@/utils/errorHandler';
import { storageInstances } from '@/utils/storageInstances';

export function useAuthStore() {
  const commit = useCommit();
  const isAuthenticated = useSelector((state) => state.auth.isAuthorized);

  const setAuthorization = (token: string, refreshToken: string) => {
    setAuthHeader(token);
    setRefreshTokenInterceptor();
    storageInstances.userToken.set(token);
    storageInstances.refreshToken.set(refreshToken);
    commit({ type: 'auth/setAuthorization' });
  };

  const removeAuthorization = () => {
    removeAuthHeader();
    removeRefreshTokenInterceptor();
    storageInstances.userToken.remove();
    storageInstances.refreshToken.remove();
    storageInstances.lastCompanyId.remove();
    commit({ type: 'auth/removeAuthorization' });
  };

  const setNewAuthorization = (token: string, refreshToken: string) => {
    setAuthHeader(token);
    storageInstances.userToken.set(token);
    storageInstances.refreshToken.set(refreshToken);
    commit({ type: 'auth/setAuthorization' });
  };

  const isAuthorized = async (temporalSupplierToken?: string) => {
    const previousToken =
      temporalSupplierToken ?? storageInstances.refreshToken.get();
    try {
      if (isAuthenticated.value) {
        return true;
      } else if (previousToken) {
        const { requestToken } = useAuthService();
        const response = await requestToken({
          refresh_token: previousToken,
          grant_type: 'refresh_token',
          client_id: import.meta.env.VITE_CRM_CLIENT_ID,
        });
        if (!response.data.user_token && !response.data.refresh_token) return;
        setAuthorization(response.data.user_token, response.data.refresh_token);
      }
      return isAuthenticated.value;
    } catch (err) {
      removeAuthorization();
      errorHandler(err);
    }
  };

  const tokenExchange = async (
    sessionId: string,
    supplierId?: string,
    distributorId?: string
  ) => {
    try {
      if (sessionId) storageInstances.sessionId.set(sessionId);
      const { requestTokenExchange } = useAuthService();
      const response = await requestTokenExchange({
        sid: sessionId,
        ...(supplierId ? { supplier_id: supplierId } : {}),
        ...(distributorId ? { distributor_id: distributorId } : {}),
      });
      if (!response.data.user_token && !response.data.refresh_token) return;
      setAuthorization(response.data.user_token, response.data.refresh_token);
    } catch (err: unknown) {
      removeAuthorization();
      errorHandler(err);
    }
  };

  return {
    isAuthenticated,
    setAuthorization,
    removeAuthorization,
    setNewAuthorization,
    isAuthorized,
    tokenExchange,
  };
}
