<template>
  <GlobalNotification />
  <ZStackableNotifications
    :paddingX="screenType === 'mobile' ? 'sm' : 'md'"
    :class="`stackable-notifications--${screenType}`"
  />
  <router-view />
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue';
import {
  UseStackableNotificationsContext,
  ZStackableNotifications,
  useMakeStackableNotificationsInstance,
  useScreenWidth,
} from '@zoomcatalog/design-system';
import { GlobalNotification } from '@/components/feedback';

const screenWidth = useScreenWidth();
const screenType = computed(() =>
  screenWidth.value === 'sm' ? 'mobile' : 'desktop'
);

useMakeStackableNotificationsInstance({
  options: reactive({
    position: 'bottom-left',
    reverse: true,
    collapsed: computed(() => screenType.value === 'mobile'),
  }) as UseStackableNotificationsContext['options'],
});
</script>

<style lang="scss" scoped>
.stackable-notifications {
  &--mobile {
    @apply h-[calc(100vh-72px)];
  }
}
</style>
