import api from '@/config/axios';
import {
  AUTH_POST_REFRESH_TOKEN,
  SSO_POST_TOKEN_EXCHANGE,
  SSO_GET_PRESIGNIN,
} from '@/config/endpoints';
import { useSelector } from '@/store/rootStore';

const endpoints = useSelector((state) => state.sniffer.endpoints);

export type TokenResponse = {
  user_token: string;
  refresh_token: string;
};

export type ImplicitTokenPayload = {
  client_id: string;
  for_application: true;
  grant_type: 'implicit';
};

export type ImplicitTokenResponse = {
  implicit_token: string;
};

export const useAuthService = () => {
  function requestTokenExchange(payload: Record<string, unknown>) {
    return api.post<TokenResponse>(SSO_POST_TOKEN_EXCHANGE.endpoint, payload, {
      baseURL: endpoints.value.SSO_API_ENDPOINT,
    });
  }

  function openPreSignInForm() {
    const companyId = useSelector((state) => state.project.data.company_id);
    const baseURL = endpoints.value.SSO_API_ENDPOINT;
    const presigninURL = SSO_GET_PRESIGNIN.endpoint(navigator.language);
    const params = new URLSearchParams({
      callback: location.href,
      client_id: import.meta.env.VITE_CLIENT_ID,
      ...(companyId.value
        ? { company_id: companyId.value, supplier_id: companyId.value }
        : {}),
      back_portal: location.href,
    });

    window.location.href = `${baseURL}/${presigninURL}?${params}`;
  }

  function requestToken(payload: Record<string, unknown>) {
    return api.post<TokenResponse>(AUTH_POST_REFRESH_TOKEN.endpoint, payload, {
      baseURL: endpoints.value.AUTH_API_ENDPOINT,
    });
  }

  function requestImplicitToken() {
    const implicitTokenPayload: ImplicitTokenPayload = {
      client_id: import.meta.env.VITE_SL_CLIENT_ID,
      for_application: true,
      grant_type: 'implicit',
    };

    return api.post<ImplicitTokenResponse>(
      AUTH_POST_REFRESH_TOKEN.endpoint,
      implicitTokenPayload,
      {
        baseURL: endpoints.value.AUTH_API_ENDPOINT,
      }
    );
  }

  function canvasSignInUrl(url: string) {
    return api
      .post<{ url: string }>(
        '/auth/authorize/url',
        {
          url,
          client_id: import.meta.env.VITE_CRM_CLIENT_ID,
        },
        {
          baseURL: endpoints.value.AUTH_API_ENDPOINT,
        }
      )
      .then((data) => data.data);
  }

  return {
    requestToken,
    requestTokenExchange,
    openPreSignInForm,
    canvasSignInUrl,
    requestImplicitToken,
  };
};
