import * as Sentry from '@sentry/vue';
import { useNotification } from '@zoomcatalog/design-system';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isEnqueueNotification } from '@/components/feedback';
import { useAuthStore } from '@/composable/store-utils/useAuthStore';
import { useAuthService } from '@/service/request-handlers/useAuthService';
import { useCommit, useSelector } from '@/store/rootStore';

// Create Instances
const api = axios.create();

// Headers
export const setAuthHeader = (token: string) => {
  api.defaults.headers.common['Authorization'] = token;
};

export const removeAuthHeader = () => {
  delete api.defaults.headers.common['Authorization'];
};

export const setBaseUrl = (baseUrl: string) => {
  api.defaults.baseURL = baseUrl;
};

// Interceptors
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const commit = useCommit();
    const notificationState = useSelector((state) => state.notification);
    const notificationHandlers = useNotification({
      onNotificationEvent(event) {
        if (isEnqueueNotification(event)) {
          commit({ type: event.type, payload: event.payload });
        }
      },
    });
    if (error.response?.status && ![401, 423].includes(error.response.status)) {
      Sentry.captureException(error);
    }

    if (
      error.response?.status &&
      error.response.status >= 500 &&
      !notificationState.value.open
    ) {
      notificationHandlers.enqueueNotification({
        color: 'danger',
        message: 'Something went wrong. Try refreshing or contact support',
        delay: 8000,
      });
    }
    return Promise.reject(error);
  }
);

const interceptorsInstance = {
  refreshToken: {} as { [key: string]: unknown },
};
export const setRefreshTokenInterceptor = () => {
  interceptorsInstance.refreshToken.ref = api.interceptors.response.use(
    (response: AxiosResponse) => {
      interceptorsInstance.refreshToken.retried = false;
      return response;
    },
    async (error: AxiosError) => {
      if (error.response && [401].includes(error.response.status)) {
        const { config } = error;
        const { setNewAuthorization, removeAuthorization } = useAuthStore();
        const { requestToken } = useAuthService();
        if (
          !interceptorsInstance.refreshToken.retried &&
          localStorage.getItem('refreshToken')
        ) {
          const response = await requestToken({
            refresh_token: localStorage.getItem('refreshToken'),
            grant_type: 'refresh_token',
            client_id: import.meta.env.VITE_CRM_CLIENT_ID,
          });
          if (!response.data.user_token && !response.data.refresh_token) return;
          setNewAuthorization(
            response.data.user_token,
            response.data.refresh_token
          );
          if (config?.headers) {
            config.headers['Authorization'] = response.data.user_token;
          }
          interceptorsInstance.refreshToken.retried = true;
          return api(config as AxiosRequestConfig);
        } else {
          removeAuthorization();
          location.reload();
        }
      }
      return Promise.reject(error);
    }
  );
};

export const removeRefreshTokenInterceptor = () => {
  if (typeof interceptorsInstance.refreshToken.ref === 'number') {
    api.interceptors.response.eject(interceptorsInstance.refreshToken.ref);
  }
};

export default api;
