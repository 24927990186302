// AUTH ENDPOINTS
export const AUTH_POST_REFRESH_TOKEN = {
  method: 'post',
  endpoint: 'auth/authorize',
};
export const SSO_POST_TOKEN_EXCHANGE = {
  method: 'post',
  endpoint: 'auth/authorize/token/exchange',
};
export const SSO_GET_PRESIGNIN = {
  method: 'get',
  endpoint: (language: string) => `signout/${language}`,
};

// USER ENDPOINTS
export const USER_GET = {
  method: 'get',
  endpoint: 'user',
};

// COMPANY ENDPOINTS
export const GET_COMPANY = {
  method: 'get',
  endpoint: `company`,
};
export const GET_COMPANY_SETTINGS_BY_KEY = {
  method: 'get',
  endpoint(settingKey: string) {
    return `company/setting/${settingKey}`;
  },
};
export const COMPANY_INFO_GET = {
  method: 'get',
  endpoint: `company/info`,
};
export const GET_USER_COMPANIES = {
  method: 'get',
  endpoint: 'user/companies',
};
export const GET_USER_DISTRIBUTOR_COMPANIES = {
  method: 'get',
  endpoint: (userId: string) =>
    `user/${userId}/company/relationships/distributor`,
};
export const GET_COMPANY_BY_ID = {
  method: 'get',
  endpoint: (companyId: string) => `company/${companyId}`,
};

// TEMPLATE ENDPOINTS
export const POST_CLONE_TEMPLATE = {
  method: 'post',
  endpoint: (projectId: string) => `clone-template/${projectId}`,
};

// ASSET ENDPOINTS
export const GET_ASSET_LIBRARY = {
  method: 'get',
  endpoint: (tags: string[] = []) => `/asset/tag/${tags.join('+')}`,
};
