import { EnqueueNotification } from '@zoomcatalog/design-system';

export { default as GlobalNotification } from './GlobalNotification.vue';

// move to typeguard to jslib
export const isEnqueueNotification = (
  event: unknown
): event is EnqueueNotification => {
  return (
    typeof event === 'object' &&
    event !== null &&
    'type' in event &&
    'payload' in event
  );
};
